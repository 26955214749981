import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import AppWrapper from './AppWrapper'; // Import AppWrapper
import './i18n'; // Assuming this is the i18n configuration file

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading translations...</div>}>
      <Router>
        <AppWrapper />
      </Router>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);