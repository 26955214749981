import React from 'react';
import { withTranslation } from 'react-i18next';
import './App.scss';

import 'gridstack';
import 'gridstack/dist/gridstack.min.css';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import Filter from './components/Filter';
import Selector from './components/Selector';
import Moodboard from './components/Moodboard';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMoodboardActive: false,
      selectedItems: [],
      moodboardItems: [],
      showModal: false,
    };
  }

  componentDidMount() {
    const { i18n } = this.props;
    const language = window.location.pathname.split('/')[1];
    if (['en', 'sl'].includes(language)) {
      i18n.changeLanguage(language);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.updateLanguage();
    }
  }

  updateLanguage = () => {
    const { i18n } = this.props;
    const language = window.location.pathname.split('/')[1];
    if (['en', 'sl'].includes(language)) {
      i18n.changeLanguage(language);
    } else {
      i18n.changeLanguage('it'); // Default to Italian
    }
  }

  // Function to handle opening the modal
  handleOpenModal = () => {
    this.setState({ showModal: true });
  };

  // Function to handle closing the modal
  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  toggleMoodboard = () => {
    this.setState(prevState => ({
      isMoodboardActive: !prevState.isMoodboardActive
    }));
  }

  // Reference to Moodboard component
  moodboardRef = React.createRef();

  addItem = (newItem) => {
    this.setState(prevState => {
      // Check if the newItem already exists in the selectedItems array
      const isItemAlreadyAdded = prevState.selectedItems.some(item => item.id === newItem.id);
  
      if (!isItemAlreadyAdded) {
        // If the item is not already added, then add it
        return {
          selectedItems: [...prevState.selectedItems, newItem],
        };
      }
  
      // If the item is already added, do not modify the state
      return prevState;
    });
  };

  addItemToMoodboard = (item) => {
    if (this.moodboardRef.current) {
      this.moodboardRef.current.addItemToMoodboard(item);
    }
  };

  // addItemToMoodboardfromSelector = (itemNew) => {
  //   // Check if the item already exists in the moodboard
  //   const existingItem = this.state.items.find(item => item.id === itemNew.id);
  //   if (!existingItem) {
  //     // Item does not exist, add it to the moodboard
  //     this.setState(prevState => ({
  //       items: [...prevState.items, itemNew]
  //     }));
  //     // Add the new item as a widget to the grid
  //     if (this.moodboardRef.current) {
  //       this.moodboardRef.current.addItemToMoodboard(itemNew);
  //     }
  //   } else {
  //     // Item already exists, do not add it again
  //     console.log('Item already exists in the moodboard:', itemNew);
  //   }
  // };

  handleRemoveItemFromSelector = (itemId) => {
    this.setState(prevState => ({
      selectedItems: prevState.selectedItems.filter(item => item.id !== itemId)
    }));
  };

  handleRemoveItemFromMoodboard = (itemId) => {
    this.setState(prevState => ({
      moodboardItems: prevState.moodboardItems.filter(item => item.id !== itemId)
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();
  
    const emailData = {
      name: document.getElementById('name').value,
      email: document.getElementById('email').value,
      message: document.getElementById('message').value,
      selectedItems: this.state.moodboardItems.map(item => `ID: ${item.id}, Name: ${item.name}`).join(', '),
    };
  
    // For sending email, you can use a server-side API or a service like EmailJS or similar
    console.log(emailData); // Replace with actual email sending logic
    this.handleCloseModal();
  };

  render() {
    const { t } = this.props;
    const { isMoodboardActive } = this.state;
    const moodboardClass = isMoodboardActive ? 'moodboard' : '';

    return (
      <div id="app" className={moodboardClass}>
        <div className="moodboard-artboard">
          <div className="container-fluid">
            <div className="row">
              <Filter 
                onAddItem={this.addItem} 
                onAddItemToMoodboard={this.addItemToMoodboard} // Passing the method as a prop
              />
              <Selector
                selectedItems={this.state.selectedItems}
                onRemoveItem={this.handleRemoveItemFromSelector}
                onAddItemToMoodboard={this.addItemToMoodboard} // Passing the method as a prop
              />
              <Moodboard ref={this.moodboardRef} items={this.state.moodboardItems} />
            </div>
          </div>
        </div>
        <div className="btns-board">
          <button className="btn-board" id="btnMoodboard" onClick={this.toggleMoodboard}>
            <span>{t('view_your_moodboard')} <span className="arrow right">›</span></span>
            <span><span className="arrow">‹</span> {t('all_samples')}</span>
          </button>
          <Button className="btn-board-order" onClick={this.handleOpenModal}>
            {t('order_samples')} <span className="arrow right">›</span>
          </Button>
        </div>

        <Modal className="bg-black" dialogClassName="modal-xl modal-dialog-centered" show={this.state.showModal} onHide={this.handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{t('send_moodboard')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-sm-6 col-xl-5">
                <h5 className="mb-4">{t('order_real_samples')}</h5>
                <ul className="order-samples">
                  {this.state.selectedItems.map(item => (
                    <li key={item.id}>
                      <img src={item.thumb} alt={item.name} style={{ width: '50px', height: '50px' }} />
                      <span>{item.name}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-sm-6 col-xl-7">
                <h5 className="mb-4">&nbsp;</h5>
                <form id="contact-form" onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="name">{t('name')}</label>
                    <input type="text" className="form-control" id="name" required />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">{t('email')}</label>
                    <input type="email" className="form-control" id="email" required />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">{t('message')}</label>
                    <textarea className="form-control" id="message" rows="3" required></textarea>
                  </div>
                  <Button type="submit" variant="primary">{t('send')}</Button>
                </form>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(App);
