// In a new file, e.g., AppWrapper.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import App from './App';

const AppWrapper = () => {
  const location = useLocation();
  return <App location={location} />;
};

export default AppWrapper;