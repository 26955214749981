import React, { Component } from 'react';
import { GridStack } from 'gridstack';
import 'gridstack/dist/gridstack.min.css';

class Moodboard extends Component {
  constructor(props) {
    super(props);
    this.moodboardRef = React.createRef();
    this.grid = null;
  }

  componentDidMount() {

    this.initGridStack();
  }

  initGridStack() {
    this.grid = GridStack.init({
      cellHeight: 'auto',
      margin: 0,
      minRow: 6,
      resizable: {
        enabled: true,
        handles: 'e, se, s, sw, w, nw, n'
      },
      draggable: {
        handle: '.grid-stack-item-content'
      },
    }, this.moodboardRef.current);
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.items !== this.props.items) {
  //     this.renderGridItems();
  //   }
  // }

  addItemToMoodboard = (item, index) => {
    const isItemAlreadyInMoodboard = this.grid.engine.nodes.some(node => node.id === item.id);
    if (!isItemAlreadyInMoodboard) {
      const widgetHtml = `
        <div class="grid-stack-item-content">
          <img src="${item.image}" alt="${item.name}" />
          <button class="removeWidget" data-id="${item.id}">
          <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="minus-circle" className="svg-inline--fa fa-minus-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#fff" d="M140 274c-6.6 0-12-5.4-12-12v-12c0-6.6 5.4-12 12-12h232c6.6 0 12 5.4 12 12v12c0 6.6-5.4 12-12 12H140zm364-18c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-32 0c0-119.9-97.3-216-216-216-119.9 0-216 97.3-216 216 0 119.9 97.3 216 216 216 119.9 0 216-97.3 216-216z"></path></svg>
          </button>
        </div>`;
  
      // Add widget to the grid
      this.grid.addWidget({
        x: (index % 3) * 4,
        y: Math.floor(index / 3) * 3,
        w: 4,
        h: 4,
        content: widgetHtml,
        id: item.id
      });

      // Attach event listeners to remove buttons
      document.querySelectorAll('#moodboardStack .removeWidget').forEach(button => {
        button.addEventListener('click', (event) => {
          const itemId = parseInt(event.currentTarget.getAttribute('data-id'), 10);
          this.handleRemove(itemId);
        });
      });
    }
  };

  handleRemove = (itemId) => {
    // Find and remove the item from the grid and state
    const element = this.moodboardRef.current.querySelector(`.grid-stack-item[gs-id="${itemId}"]`);
    if (element) {
      this.grid.removeWidget(element);
      // this.setState(prevState => ({
      //   items: prevState.items.filter(item => item.id !== itemId)
      // }));
    }
  };

  componentWillUnmount() {
    if (this.grid) {
      this.grid.destroy();
    }
  }

  render() {
    return (
      <div className="col px-0 moodboard-board order-1">
        <div id="moodboardStack" className="grid-stack" ref={this.moodboardRef}></div>
      </div>
    );
  }
}

export default Moodboard;
