import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { GridStack } from 'gridstack';
import 'gridstack/dist/gridstack.min.css';

class Selector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawerClosed: false,
      selectedItems: props.selectedItems, // Initialize from props
    };
    this.grid = null; // GridStack instance
  }

  componentDidMount() {
    this.initializeGridStack();
  }

  componentDidUpdate(prevProps) {
    // Check if the selectedItems prop has changed
    if (prevProps.selectedItems !== this.props.selectedItems) {
      this.setState({ selectedItems: this.props.selectedItems });
      // Update the grid with new items if necessary
      // This could include adding new items to the grid
      // And/or removing items no longer in selectedItems
    }
  }

  componentWillUnmount() {
    this.destroyGridStack();
  }

  initializeGridStack = () => {
    this.grid = GridStack.init({
      cellHeight: 'auto',
      margin: 0,
      minRow: 6,
      resizable: {
        enabled: true,
        handles: 'e, se, s, sw, w, nw, n'
      },
      draggable: {
        handle: '.grid-stack-item-content'
      },
      // Additional options
    }, this.gridRef);
  };

  destroyGridStack = () => {
    if (this.grid) {
      this.grid.destroy();
    }
  };

  handleDrawerClick = () => {
    this.setState(prevState => ({
      isDrawerClosed: !prevState.isDrawerClosed,
    }));
  };

  handleAddItem = (newItem) => {
    this.setState(prevState => {
      const isItemAlreadyAdded = prevState.selectedItems.some(item => item.id === newItem.id);
  
      if (!isItemAlreadyAdded) {
        return {
          selectedItems: [...prevState.selectedItems, newItem],
          moodboardItems: [...prevState.moodboardItems, newItem]
        };
      }
  
      return prevState;
    });
  };

  handleAddClick = (item) => {
    // this.props.onAddItem(item); // Call the onAddItem prop function
    if (item) {
      this.props.onAddItemToMoodboard(item);
    }
  }

  removeItem = (itemId) => {
    const element = document.querySelector(`.grid-stack-item[data-id="${itemId}"]`);

    if (element) {
      this.grid.removeWidget(element);
      this.props.onRemoveItem(itemId);
    }
  };

  renderGridItems = () => {
    const { t } = this.props;
    return this.state.selectedItems.map(item => (
      <div key={item.id} data-id={item.id} className="cardWidget newWidget grid-stack-item">
        <div className="grid-stack-item-content">
          <img className="img-fluid" src={item.thumb} alt={item.name} />
          <p>{item.name}</p>
          <button className="btn-add-to-grid" data-id={item.id} onClick={() => this.handleAddClick(item)}>
            <span className="d-block">«</span> {t('add_to_moodboard')}
          </button>
          <button className="btn-remove" onClick={() => this.removeItem(item.id)}>
            <svg width="20" height="20" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" className="svg-inline--fa fa-times-circle fa-w-16" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times-circle" role="img">
              <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 464c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm94.8-285.3L281.5 256l69.3 69.3c4.7 4.7 4.7 12.3 0 17l-8.5 8.5c-4.7 4.7-12.3 4.7-17 0L256 281.5l-69.3 69.3c-4.7 4.7-12.3 4.7-17 0l-8.5-8.5c-4.7-4.7-4.7-12.3 0-17l69.3-69.3-69.3-69.3c-4.7-4.7-4.7-12.3 0-17l8.5-8.5c4.7-4.7 12.3-4.7 17 0l69.3 69.3 69.3-69.3c4.7-4.7 12.3-4.7 17 0l8.5 8.5c4.6 4.7 4.6 12.3 0 17z"></path>
            </svg>
          </button>
        </div>
      </div>
    ));
  };

  render() {
    const { t } = this.props;
    const drawerClass = this.state.isDrawerClosed ? 'drawer-close closed' : 'drawer-close';
    const sidebarWrapClass = this.state.isDrawerClosed ? 'filter-right col-3 col-xl-2 order-3 closed' : 'filter-right col-3 col-xl-2 order-3';
    const sidebarClass = this.state.isDrawerClosed ? 'col-3 col-xl-2 bg-dark order-3 h-100 filter-sidebar closed false' : 'col-3 col-xl-2 bg-dark order-3 h-100 filter-sidebar false';

    return (
      <div className={sidebarWrapClass}>
        <div className={sidebarClass}>
          <h4 className="my-4 text-center">{t('chosen_samples')}:</h4>
          <div id="gridStackFilter">
            {this.renderGridItems()}
          </div>
          <div id="drawer-close" className={drawerClass} onClick={this.handleDrawerClick}>
            <div id="arrow">
              <span><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-double-right" className="svg-inline--fa fa-angle-double-right fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="#fff" d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17zm128-17l-117.8-116c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17L255.3 256 153.1 356.4c-4.7 4.7-4.7 12.3 0 17l7.1 7.1c4.7 4.7 12.3 4.7 17 0l117.8-116c4.6-4.7 4.6-12.3-.1-17z"></path></svg></span>
              <span><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-double-left" className="svg-inline--fa fa-angle-double-left fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="#fff" d="M153.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L192.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L153 264.5c-4.6-4.7-4.6-12.3.1-17zm-128 17l117.8 116c4.7 4.7 12.3 4.7 17 0l7.1-7.1c4.7-4.7 4.7-12.3 0-17L64.7 256l102.2-100.4c4.7-4.7 4.7-12.3 0-17l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L25 247.5c-4.6 4.7-4.6 12.3.1 17z"></path></svg></span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Selector);
