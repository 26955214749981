import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const navigate = useNavigate();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language); // Set the state immediately
    navigate(`/${language}`); // Navigate to the new language route
  };

  useEffect(() => {
    const handleLanguageChange = (lng) => {
      setSelectedLanguage(lng); // Update the state when the i18n language changes
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]); // Dependency array ensures this effect runs only when i18n object changes

  return (
    <select className="custom-select" value={selectedLanguage} onChange={(e) => changeLanguage(e.target.value)}>
      <option value="en">EN</option>
      <option value="sl">SL</option>
      <option value="it">IT</option>
    </select>
  );
};

export default LanguageSwitcher;
