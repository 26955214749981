import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/translation.json';
import translationSL from './locales/sl/translation.json';
import translationIT from './locales/it/translation.json';

import filterListEN from './locales/en/filterList.json';
import filterListSL from './locales/sl/filterList.json';
import filterListIT from './locales/en/filterList.json';

import searchListsEN from './locales/en/searchLists.json';
import searchListsSL from './locales/sl/searchLists.json';
import searchListsIT from './locales/it/searchLists.json';

// the translations
const resources = {
  en: {
    translation: translationEN,
    filterList: filterListEN,
    searchLists: searchListsEN
  },
  sl: {
    translation: translationSL,
    filterList: filterListSL,
    searchLists: searchListsSL
  },
  it: {
    translation: translationIT,
    filterList: filterListIT,
    searchLists: searchListsIT
  }
};

i18n
  // .use(HttpBackend) // Omit this if you're using local JSON files.
  .use(LanguageDetector) // Detect language from the browser
  .use(initReactI18next) // Pass the i18n instance to react-i18next.
  .init({
    resources,
    lng: 'en', // default language (lowercase)
    fallbackLng: 'en', // Fallback to English if the detected language is not available
    debug: true, // Set to false in production
    interpolation: {
      escapeValue: false, // Not needed for React as it escapes by default
    },
    // backend: { // Omit this if you're using local JSON files.
    //   loadPath: '/locales/{{lng}}/{{ns}}.json',
    // },
  });

export default i18n;
