import React from "react";
import { withTranslation } from 'react-i18next';

import logo from '../logo.svg';

// import filterListData from '../json/filterList_EN.json';
// import searchListsData from '../json/searchLists_EN.json';

import LanguageSwitcher from './LanguageSwitcher';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class Filter extends React.Component {
  constructor() {
    super();
    this.state = {
      filterList: [],
      searchLists: [],
      activeFilter: [],
      showModal: false,
      selectedItem: null,
    };
  }

  loadJsonData = (language) => {
    import(`../locales/${language}/filterList.json`)
      .then(filterListData => {
        this.setState({ filterList: filterListData.default });
      });

    import(`../locales/${language}/searchLists.json`)
      .then(searchListsData => {
        this.setState({ searchLists: this.transformSearchList(searchListsData.default) });
      });
  }

  componentDidMount() {
    this.loadJsonData(this.props.i18n.language);
  }

  componentDidUpdate(prevProps) {
    if (this.props.i18n.language !== prevProps.i18n.language) {
      this.loadJsonData(this.props.i18n.language);
    }
  }

  transformSearchList = (data) => {
    return data.map(item => ({
      ...item,
      image: require(`../images/${item.image}`),
      thumb: require(`../images/thumbs/${item.image}`),
    }));
  }

  onFilterChange(filter) {
    const { filterList, activeFilter } = this.state;
    if (filter === "ALL") {
      if (activeFilter.length === filterList.length) {
        this.setState({ activeFilter: [] });
      } else {
        this.setState({ activeFilter: filterList.map(filter => filter.id) });
      }
    } else {
      if (activeFilter.includes(filter)) {
        const filterIndex = activeFilter.indexOf(filter);
        const newFilter = [...activeFilter];
        newFilter.splice(filterIndex, 1);
        this.setState({ activeFilter: newFilter });
      } else {
        this.setState({ activeFilter: [...activeFilter, filter] });
      }
    }
  }

  handleAddClick = (item) => {
    this.props.onAddItem(item); // Call the onAddItem prop function
    if (item) {
      this.props.onAddItemToMoodboard(item);
    }
  }

  // Updated handleOpenModal function
  handleOpenModal = (item) => {
    this.setState({ 
      showModal: true,
      selectedItem: item // Set the selected item
    });
  };

  // Function to handle closing the modal
  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { t, i18n } = this.props;
    const currentLanguage = i18n.language; // Get the current language
    const { filterList, activeFilter, selectedItem, searchLists } = this.state;
    let filteredList;
    if (
      activeFilter.length === 0 ||
      activeFilter.length === filterList.length
    ) {
      filteredList = this.state.searchLists;
    } else {
      filteredList = this.state.searchLists.filter(item =>
        this.state.activeFilter.includes(item.type)
      );
    }
    return (
      <div className="filter-board col h-100">
        <div className="row h-100">
          <div className="filter-left col-4 col-xl-3 h-100">
            <div className="filter-checkbox">
              <div className="row">
                <div className="App-logo col">
                  <img src={logo} alt="Rolich" />
                  <span>Moodboard</span>
                </div>
                <div className="App-lang col">
                  <LanguageSwitcher />
                </div>
              </div>
              <form id="formSamples">
                <h5 className="mb-4 pt-5">{t('show_samples')}:</h5>
                <div className="f-item">
                  <label htmlFor="myInput">
                    <input
                      id="myInput"
                      type="checkbox"
                      checked={activeFilter.length === filterList.length}
                      onChange={() => this.onFilterChange("ALL")}
                    />
                    {t('all')}
                  </label>
                </div>
                {filterList.map((filter) => {
                  const filterNameKey = `name_${currentLanguage}`; // Construct the key based on current language
                  const filterName = filter[filterNameKey] || filter.name; // Use the specific language name or default to 'name'
                  return (
                    <div className="f-item" key={filter.id}>
                      <label htmlFor={filter.id}>
                        <input
                          id={filter.id}
                          type="checkbox"
                          checked={activeFilter.includes(filter.value)}
                          onChange={() => this.onFilterChange(filter.value)}
                        />
                        {filterName}
                      </label>
                    </div>
                  );
                })}
              </form>
            </div>
          </div>
          <div className="col h-100 filter-board">
            <h3 className="my-4">{t('choose_samples')}</h3>
            {filteredList.map(item => (
              <div className="card" key={item.id}>
                <img className="img-fluid" src={item.thumb} alt={item.name} />
                <span className="card-overlay">
                  <p>{item.name}</p>
                  <p>{item.code}</p>
                  <button className="btn-add" data-id={item.id} onClick={() => this.handleAddClick(item)}>
                    <svg width="20" height="20" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" className="svg-inline--fa fa-plus-circle fa-w-16" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus-circle" role="img">
                      <path fill="#fff" d="M384 250v12c0 6.6-5.4 12-12 12h-98v98c0 6.6-5.4 12-12 12h-12c-6.6 0-12-5.4-12-12v-98h-98c-6.6 0-12-5.4-12-12v-12c0-6.6 5.4-12 12-12h98v-98c0-6.6 5.4-12 12-12h12c6.6 0 12 5.4 12 12v98h98c6.6 0 12 5.4 12 12zm120 6c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-32 0c0-119.9-97.3-216-216-216-119.9 0-216 97.3-216 216 0 119.9 97.3 216 216 216 119.9 0 216-97.3 216-216z"></path>
                    </svg>
                  </button>

                  <Button className="btn-moodboard" onClick={() => this.handleOpenModal(item)}>
                    <svg width="15" height="15" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" className="svg-inline--fa fa-expand-arrows fa-w-14" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="expand-arrows" role="img">
                      <path fill="#fff" d="M447.7 364l.3 104c0 6.6-5.4 12-12 12l-104-.3c-6.6 0-12-5.4-12-12v-10c0-6.6 5.4-12 12-12l58 .3.7-.7L224 278.6 57.3 445.3l.7.7 58-.3c6.6 0 12 5.4 12 12v10c0 6.6-5.4 12-12 12L12 480c-6.6 0-12-5.4-12-12l.3-104c0-6.6 5.4-12 12-12h10c6.6 0 12 5.4 12 12l-.3 58 .7.7L201.4 256 34.7 89.3l-.7.7.3 58c0 6.6-5.4 12-12 12h-10c-6.6 0-12-5.4-12-12L0 44c0-6.6 5.4-12 12-12l104 .3c6.6 0 12 5.4 12 12v10c0 6.6-5.4 12-12 12L58 66l-.7.7L224 233.4 390.7 66.7l-.7-.7-58 .3c-6.6 0-12-5.4-12-12v-10c0-6.6 5.4-12 12-12l104-.3c6.6 0 12 5.4 12 12l-.3 104c0 6.6-5.4 12-12 12h-10c-6.6 0-12-5.4-12-12l.3-58-.7-.7L246.6 256l166.7 166.7.7-.7-.3-58c0-6.6 5.4-12 12-12h10c6.6 0 12 5.4 12 12z"></path>
                    </svg>
                  </Button>

                </span>
              </div>
            ))}
            <Modal className="bg-black" dialogClassName="bg-black modal-lg modal-dialog-centered" show={this.state.showModal} onHide={this.handleCloseModal}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                {selectedItem && ( // Check if selectedItem is not null
                  <div className="text-center modal-body" key={selectedItem.id}>
                    <span className="modal-img"><img className="img-fluid" src={selectedItem.image} alt={selectedItem.name} /></span>
                    <p className="type">{t('category')}: {selectedItem.type}</p>
                    <p>{t('product_name')}: {selectedItem.name}</p>
                    <p>{t('code')}: {selectedItem.code}</p>
                  </div>
                )}
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Filter);
